<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:py-10 mx-auto relative"
    v-if="experience"
  >
    <div
      @click.prevent="closeCoachMark()"
      class="fixed top-0 left-0 w-screen h-full bg-black opacity-70 z-40"
      v-show="showCoachMark"
    ></div>
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div v-if="experience && !completed">
      <div
        class="w-full bg-nColorFillGray border border-nColorStrokeGray rounded-xl mt-20 md:mt-2 mb-4 text-bgcolor relative"
      >
        <div class="bg-nColorBlue rounded-tl rounded-tr px-4 pt-4 pb-3 md:py-2">
          <div
            class="text-white font-bold text-xl md:text-4xl text-left md:text-center"
          >
            {{ currentQuestion }} / {{ questions.length }}
          </div>
          <div
            class="w-36 md:w-52 h-fit absolute -top-2 right-40 md:right-44 bg-white text-black z-50 border-gray-200 shadow-2xl p-4 text-left font-medium text-xs md:text-sm rounded"
            v-show="showCoachMark"
          >
            Answer the survey and click here to go to the next question.
            <div class="arrow-right absolute -right-3 top-4 shadow-2xl"></div>
          </div>
          <button
            :id="buttons.next"
            @click.prevent="nextQuestion()"
            type="button"
            class="px-4 py-2 bg-white bg-opacity-100 rounded-lg outline-none focus:outline-none flex items-center absolute top-2 right-4"
          >
            <div
              id="nextQuestion"
              class="inline-block text-xs md:text-sm font-bold text-black"
            >
              Next Question
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="black"
              class="bi bi-arrow-right-short inline-block w-6 h-6"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </button>
        </div>
        <div v-if="actualQuestion" class="overflow-hidden">
          <Questions
            :question="actualQuestion"
            :qData="getCurrentQuestionAnswer()"
            @storeProgress="storeProgress"
          />
        </div>
        <ConfirmationModal
          @submit="submitPulse1Week()"
          @closeModal="modal.show = $event"
          :modal="modal"
        ></ConfirmationModal>
      </div>
    </div>
    <div
      class="completed text-white mt-20 md:mt-4"
      v-if="experience && completed"
    >
      <SuccessContent
        :target="'_self'"
        :successMsg="'Your survey has been submitted successfully!'"
      />
      <div class="feedback my-4 mx-4 md:mx-0 md:my-8" v-if="!showFeedback">
        <ExpFeedback :exp="obkSlugs.pulseOneWeek" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import TokenService from "@/common/token.service";
import { apiResource } from "@/common/app.config";
import SuccessContent from "@/components/Demo/SuccessContent.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Questions from "@/components/Experiences/Pulse1Week/Experience.vue";
import ConfirmationModal from "@/components/Modals/ConfirmationModal";
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import obkSlugs from "@/common/slugs";
import Heading from "@/components/Demo/Heading.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "ObkPulse1Week",
  components: {
    SuccessContent,
    MobileHeader,
    Questions,
    ConfirmationModal,
    ExpFeedback,
    Heading,
    Footer,
  },
  props: ["obkid"],
  data() {
    return {
      experience: null,
      questions: [],
      loading: false,
      testing: true,
      isEditing: true,
      showCoachMark: false,
      buttons: {
        prev: "prevButton",
        next: "nextButton",
      },
      currentQuestion: "1",
      actualQuestion: null,
      modal: {
        show: false,
        heading: "Submit survey",
        showLottie: true,
        lottiePath: "lotties/are-you-sure.json",
        question: "Are you sure you want to submit the survey?",
        action: "submit",
      },
      showFeedback: false,
      completed: false,
      feedbackEndpoint: apiResource.obk.feedback,
      obkSlugs,
    };
  },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.pulseOneWeek,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.questions = data.data.exp_details.questions;
        this.actualQuestion =
          this.questions[
            parseInt(this.currentQuestion) - 1
          ].actual_question.toString();
        this.dataFetched = true;
      })
      .catch(() => {});
  },
  updated() {
    if (!this.completed) {
      this.updateNextButton();
    } else {
      this.isEditing = false;
    }
  },
  methods: {
    submit() {},
    getHeaderText() {
      return this.experience.name;
    },
    nextQuestion() {
      if (parseInt(this.currentQuestion) + 1 == this.questions.length) {
        document.querySelector("#nextQuestion").innerHTML = "Submit";
      }
      if (parseInt(this.currentQuestion) == this.questions.length) {
        this.modal.show = true;
      } else {
        this.currentQuestion = parseInt(this.currentQuestion) + 1;
        this.actualQuestion =
          this.questions[
            parseInt(this.currentQuestion) - 1
          ].actual_question.toString();
      }
    },
    updateNextButton() {
      if (!this.getCurrentQuestionAnswer()) {
        this.disableButtonWithId(this.buttons.next);
      } else {
        this.enableButtonWithId(this.buttons.next);
      }
    },
    enableButtonWithId(id) {
      document.querySelector("#" + id).removeAttribute("disabled");
      document
        .querySelector("#" + id)
        .classList.remove("bg-opacity-40", "cursor-not-allowed");
      document
        .querySelector("#" + id)
        .classList.add("bg-opacity-100", "hover:opacity-90");
    },
    disableButtonWithId(id) {
      document.querySelector("#" + id).setAttribute("disabled", true);
      document
        .querySelector("#" + id)
        .classList.remove("bg-opacity-100", "hover:opacity-90");
      document
        .querySelector("#" + id)
        .classList.add("bg-opacity-40", "cursor-not-allowed");
    },
    storeProgress(data) {
      if (
        parseInt(this.currentQuestion) == 1 &&
        !TokenService.getToken("coach-mark-pulse-1-week")
      ) {
        this.showCoachMark = true;
      }
      this.questions[parseInt(this.currentQuestion) - 1].answer = data;
    },
    getCurrentQuestionAnswer() {
      return this.questions[parseInt(this.currentQuestion) - 1].answer;
    },
    submitPulse1Week() {
      this.showFeedback = false;
      this.completed = true;
      this.isEditing = false;
      //   ApiService.post(apiResource.obk.pulse1Week.addResult, {
      //     exp_link_id: this.obkid,
      //     data: this.questions,
      //   })
      //     .then(() => {})
      //     .catch(() => {});
    },
    closeCoachMark() {
      TokenService.saveToken("coach-mark-pulse-1-week", true);
      this.showCoachMark = false;
    },
  },
};
</script>

<style scoped>
.arrow-right {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid white;
}
</style>
