<template>
  <div>
    <div
      class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl w-full relative left-1/2 transform -translate-x-1/2 hidden md:block text-black pb-6"
    >
      <lottie-animation
        path="lotties/success.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :width="512"
        :height="512"
      />
      <p class="text-xl text-center mt-8">{{ successMsg }}</p>
      <p class="text-center" v-if="link">
        <a class="text-white underline" :href="link" :target="target">{{
          link
        }}</a>
      </p>
    </div>
    <div
      class="w-full relative left-1/2 transform -translate-x-1/2 block md:hidden"
    >
      <lottie-animation
        path="lotties/success.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :width="256"
        :height="256"
      />
      <p class="text-base text-center mt-8">{{ successMsg }}</p>
      <p class="text-center" v-if="link">
        <a class="text-white underline" :href="link" :target="target">{{
          link
        }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
  props: {
    successMsg: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },

    target: {
      type: String,
      default: () => "_blank",
    },
  },
};
</script>

<style scoped></style>
